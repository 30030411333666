<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import RecentActivitySchedule from "./recent-activity-schedule";
import Multiselect from "vue-multiselect";
import useHttp from '@/comp-functions/useHttp'
import Swal from "sweetalert2";
import { ref, onMounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/libs/vee-validate'

const FORM_INIT= {
      flightStatus: '',
      remaks:''
}
export default {
  page: {
    title: "Update Flight Status",
    meta: [{ name: "description", content: appConfig.description }]
  },
  props:{
    schedule_id: String,
  },
  components: { 
    RecentActivitySchedule, Layout, PageHeader, Multiselect, 
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      title: "Update Flight Status",
      items: [],
      select_label: "Select",
      status: "not_accepted",
      checkCustom: "not_accepted",
      checked: true,
      value_flightstatus: null
    };
  },
  setup(props){

    const { $get } = useHttp()
    const { $post } = useHttp()
    const formRef = ref(null)
    const LOV = ref({
      flightStatus: [],
    })

    const getFlightStatus = async () => { 
      const {data} = await $get({ url: 'master/codes/category/FLS'})
      LOV.value.flightStatus = data
      form.value.scheduleId = props.schedule_id
      scheduleId.value = form.value.scheduleId
    }

    const scheduleId = ref('')
    const selectedSchedule = ref({})
    const form = ref({...FORM_INIT, scheduleId: ''})

    const validationForm = async (event) => {
      event.target.disabled = true
      const validated = await formRef.value.validate()
      if(validated){
        scheduleId.value = ''
        let dataSubmit = {
            hscStatuscode : form.value.flightStatus.lbc_code,
            hscScheduleId : form.value.scheduleId,
            hscRemaks : form.value.remaks
        }
        await $post({
          url: 'master/schedule_status',
          data: dataSubmit
        })
        scheduleId.value = form.value.scheduleId
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: 'New status has been added successfully',
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        form.value = {...FORM_INIT, scheduleId: form.value.scheduleId}
        formRef.value.reset();
      }
        event.target.disabled = false
    }

    const getSchedule = async () => {
      const {data} = await $get({ url: `master/schedule_v2/${props.schedule_id}`})
      selectedSchedule.value = data
    }

    onMounted(() => {
      getSchedule()
      getFlightStatus()
    })


    return {
      selectedSchedule,
      LOV,
      getFlightStatus,
      // selectedFlightStatus,
      scheduleId,
      validationForm,
      form,
      formRef,
      required
    }


  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
               <div class="col-12">
                <validation-observer ref="formRef">
                  <b-form  class="form-horizontal" role="form" @submit.prevent>
                  <b-form-group
                    label-cols-sm="2"
                    label="Flight#"
                  >
                    <label class="col-sm-9">{{selectedSchedule.sch_flight_no}}</label>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label="Route"
                  >
                    <label class="col-sm-9">{{selectedSchedule.oriprt_locationname}} ({{selectedSchedule.oriprt_initial}}) <i class="ri-arrow-right-line"></i>  {{selectedSchedule.desprt_locationname}} ({{selectedSchedule.desprt_initial}})</label>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label="Schedule"
                  >
                    <label class="col-sm-9">{{selectedSchedule.sch_departure_date }} {{ selectedSchedule.sch_departure_time }} <i class="ri-arrow-right-line"></i> {{selectedSchedule.sch_arrival_time}}</label>
                  </b-form-group>
                  
                  <b-form-group
                    label-cols-sm="2"
                    label="Flight Status"
                    label-for="customer_code"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="flightstatus"
                      rules="required"
                    >
                    <multiselect v-model="form.flightStatus" :options="LOV.flightStatus" label="descr_id" track-by="descr_id" :select-label="select_label"></multiselect>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Schedule"
                    label-for="schedule_id"
                  >
                    <b-form-input  v-model="form.schedule_id" id="" name="" value="1"></b-form-input>
                  </b-form-group> -->
                  <b-form-group
                    label-cols-sm="2"
                    label="Remaks"
                    label-for="customer_code"
                  >
                   <b-form-input v-model="form.remaks" id="" name="" value=""></b-form-input>
                  </b-form-group>

                  </b-form>
                </validation-observer>

                <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary" @click.prevent="validationForm">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger" :to="{name: 'appsmd-schedule-list'}">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>

              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      
      <div class="col-lg-12">
        <RecentActivitySchedule :schId="scheduleId"/>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
