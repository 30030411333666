<script>
import useHttp from '@/comp-functions/useHttp'
import { ref, onMounted, toRefs, watch } from '@vue/composition-api'

export default {
  props: {
    schId: String
  },
  setup(props)
  {
      const { schId } = toRefs(props)

      const { $get } = useHttp() 

     const scheduleStatus = ref({})
     

    const getHistorySchedule = async () => { 
      if(schId.value > 0){
        const {data} = await $get({ url: 'master/schedule_hist/'+schId.value})
        scheduleStatus.value = data
      }
    }
    watch(schId, getHistorySchedule)

 onMounted(() => {
   if (schId.value ) {
    getHistorySchedule()
   }
     
    })


    //   const getHistorySchedule = async () => {
    //   $get({
    //     url: 'master/schedule_hist/:id',
    //     data: activityData
    //   })
        
    // }

      return{
        //   activityData,
          scheduleStatus,
          getHistorySchedule,
          
      }

  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Activity History</h4>
      <div data-simplebar>
        <ul class="list-unstyled activity-wid">
          <li class="activity-list" v-for="(data, index) in scheduleStatus" :key="index">
            <div class="activity-icon avatar-xs">
              <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                <i class="mdi mdi-map-marker"></i>
              </span>
            </div>
            <div>
              <div>
                <h5 class="font-size-13">
                  {{data.hsc_status_datetime}} - {{data.user.usr_fullname}}
                </h5>
              </div>

              <div>
                <p class="text-muted mb-0">{{data.hscStatus.descr_en}} 
                  <span v-if="data.hsc_remaks">
                    - {{data.hsc_remaks}}
                  </span>
                  </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>